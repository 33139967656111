/** @jsx jsx */
import { jsx, Button, Flex, Box, Container, Styled } from 'theme-ui'
import React, {useContext, useState, useEffect} from 'react'
import SEO from "../components/seo"
import { FirebaseContext } from "../context"
import { SignUp } from '../components/Auth'
import Spin from '../images/spinner.gif'
const Profile = () => {


  const { user, loading } = useContext(FirebaseContext)
  const [modal, setModal] = useState(false)
  const [spinner, setSpinner] = useState(true)
  const [u, setU] = useState({
    username: '',
    email: '',
    breeder: false,
  })

  useEffect(() => {
    if (user && !loading) {
      setU({
        username: user.username,
        email: user.email,
        breeder: user.breeder
        // add breederId
      })
      setSpinner(false)
      setModal(false)
    } else if (loading) {
      setSpinner(true)
    } else if (!user && !loading) {
      setSpinner(false)
      setModal(true)
    }
  }, [user,loading])

  return (
    <section>
      <SEO title="User Profile" />

      <Container sx={{px: [5,5,0], mt: 5, maxWidth: 'max'}}>
        <Flex sx={{width: '100%', justifyContent: 'space-between', alignItems: 'center'}}>
          <Box>
            <Styled.h1 sx={{mb: '1'}}>@{u.username}</Styled.h1>
            <Styled.h6>{u.email}</Styled.h6>
          </Box>
          <Box>
            {u.breeder && (
              <Button>+ Add Dog</Button>
              // Create a dog form that assigns a dog to a kennel using the breederId.
            )}
          </Box>
        </Flex>

        <Box sx={{my: 5}}>
          <Styled.p>This is your profile.</Styled.p>
        </Box>
      </Container>

      {modal && (
        <>
          <div sx={{zIndex: '3000', position: 'fixed', top: '0', bottom: '0', left: '0', right: '0', width: '100%', height: '100vh', opacity: '1', backgroundColor: '#fafafa'}}></div>
          <div sx={{zIndex: '3001', position: 'fixed', top: '0', bottom: '0', left: '0', right: '0', width: '100%', height: 'auto'}}>
            <Flex sx={{alignItems: 'center', justifyContent: 'center', height: '100vh', mx: 'auto'}}>
              <Box sx={{p: 5, backgroundColor: 'white', width: 'small',boxShadow: 'medium', borderRadius: 'medium' }}>
                <SignUp redirect={''}/>
              </Box>
            </Flex>
          </div>
        </>
      )}

      {spinner &&  (
        <>
          <div sx={{zIndex: '3000', position: 'fixed', top: '0', bottom: '0', left: '0', right: '0', width: '100%', height: '100vh', opacity: '0.75', backgroundColor: '#fafafa'}}></div>
          <div sx={{zIndex: '3001', position: 'fixed', top: '0', bottom: '0', left: '0', right: '0', width: '100%', height: 'auto'}}>
            <Flex sx={{alignItems: 'center', justifyContent: 'center', height: '100vh', mx: 'auto'}}>
                <img src={Spin} width="150px" />
            </Flex>
          </div>
        </>
      )}
    </section>
  )
}

export default Profile